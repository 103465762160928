import React, { Component } from 'react'


export class Feedback extends Component {
    render() {
    if (!this.props.open) return null
        return (
        <>
        <div className='modalOverlay'></div>
        <div className='feedbackModal' onMouseLeave={this.props.onClose}>
            
            <form className ="feedbackForm" onSubmit={() => this.props.FeedbackSubmit}>
            <label className="feedbackLabel"><strong>Bug? Wrong/Insane info? Improvement suggestions?<br></br> Send a message</strong></label><br></br>
            <textarea className="feedbackArea" onChange={this.props.userMessageChange} value={this.props.userMessage} rows="3" cols="50" placeholder="Short message here." maxLength='500' type="text">
            
            </textarea>
            <button className={`button ${document.body.className === 'light' ? 'light' : '' }`} onClick={this.props.FeedbackSubmit} > Submit </button>
            </form>
        </div>
        </>
        )
    }
}
export default Feedback

