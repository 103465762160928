import React, { Component } from 'react'
import Feedback from './Feedback'
import axios from 'axios'



export class Footer extends Component {
   
    state = {
        isOpen : false,
        userMessage : "",
        feedbacked : false
        }
    setIsOpen = async () => {
        await this.setState ({
            isOpen : !this.state.isOpen
        })
    }
    userMessageChange = async (event) => {
        this.setState({
            userMessage : event.target.value
        })
    }

    FeedbackSubmit = async (event) => {
        event.preventDefault();
        let {userMessage} = this.state;
        
        this.setIsOpen();
        this.setState({feedbacked : true})
        if (window.location.href === 'http://localhost:3000/'){
            await axios.post('http://localhost:5000/feedback/',userMessage)
        .then(res => console.log(res.message))
        .catch(err => console.log(err.message)) 
          
        }else{
            await axios.post(window.location.href+'feedback/', userMessage)
            .then(res => console.log(res.message))
            .catch(err => console.log(err.message)) 
        }
    }

    render() {
        if (!this.state.feedbacked){
        return (
            <footer className={`footer ${document.body.className === 'light' ? 'light' : '' }`}>
        
        <Feedback
            userMessage={this.state.userMessage}
            userMessageChange={this.userMessageChange}
            FeedbackSubmit={this.FeedbackSubmit}
            open={this.state.isOpen} onClose={this.setIsOpen}
        />
        <div className="feedbackLink" onMouseEnter={()=>this.setIsOpen()}>Feedback</div>
        </footer>
        )}else{
            return (
            <footer className={`footer ${document.body.className === 'light' ? 'light' : '' }`}>
        
            
            <div className="feedbackLink" >Thanks</div>
            </footer>
            )
        }
        
    }
}
export default Footer
