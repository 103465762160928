import React, { Component } from 'react'
import score0 from '../images/score0.svg'
import score1 from '../images/score1.svg'
import score2 from '../images/score2.svg'
import score3 from '../images/score3.svg'
import score4 from '../images/score4.svg'
import {Chevron} from './Chevron'
//https://coolors.co/13242a-4c8577-5cc9ff-ebebeb-d6aec0

export class ResortElement extends Component {
    
    
    render() {
        return (
            <div className="containerContainer">
            <div className="cardContainer">
                
                    {
                        this.props.closeResorts.map(function(resort, index){
                          
                            return (
                                
                                <div key={index} className={`resortCard ${document.body.className === 'light' ? 'light' : '' }`} >
                                    <div className="rcardInner">

                                        <div>                                        
                                            <img className={`powScoreImg ${document.body.className === 'light' ? 'light' : '' }`} src={resort.powScore === '0' ? score0 : resort.powScore === '1' ? score1 : resort.powScore === '2' ? score2 : resort.powScore === '3' ? score3 : resort.powScore >= '4' ? score4 : ''} alt={''} />
                                            <img src={resort.iconURL1} alt=''></img>
                                        </div>
                                        
                                        <div className="resortName"><strong><a href={resort.url}>{resort.name}</a></strong>  ~ {(resort.distance).toFixed(0)} mi</div>
                                        
                                        <div className="textElements">
                                            
                                            <div><strong>{`${resort.newSnow === null ? '' : 'New Snow: '+resort.newSnow+'"' }`}</strong></div>
                                            <div>{`${resort.temp === undefined ? '' : ' Temp: '+resort.temp+'°F' }`}</div>
                                            <div>{resort.shortForecast}</div>
                                            <div>{`${resort.nextSnow === "None" ? '' : 'Snow Forecasted: '+resort.nextSnow}`}</div>

                                        </div>
                                          <div className="forecast" >
                                            <div><strong>{resort.periodName}:</strong> {resort.detailedForecast}</div>
                                            <div><strong>{resort.forwardName}:</strong> {resort.futureDetailedForecast}</div>
                                          </div>
                                          
                                          <div className="expandChevron" onClick={()=>{document.getElementsByClassName("forecast")[index].classList.toggle('open')
                                                                                       document.getElementsByClassName("expandChevron")[index].classList.toggle('rotate')}} ><Chevron width={20} fill={'#777'}  /></div>
                                    </div>
                                    
                                  
                                   
                                </div>                                
                            )
                        })
                    }
                
                <div className="bbutton">
                    <button className={`button ${document.body.className === 'light' ? 'light' : '' }`} id='back' onClick={this.props.prevStep}> Back </button>
                </div>
                
            </div>
            </div>
        )
    }
}

export default ResortElement
