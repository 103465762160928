import React, { Component } from "react";

export class ZipForm extends Component {
	// {`${this.props.values.spinner ? "lds-roller" : ""}`}
	render() {
		return (
			<div className="contentSection">
				<h1>What resort near me has the best snow?</h1>
				<form className="ZipForm" onSubmit={this.props.handleSubmit}>
					<input
						className="inputZip"
						type="text"
						placeholder="Enter Zipcode"
						onChange={this.props.userZipChange}
						min="0"
						max="99999"
					/>

					<button
						className={`button ${
							document.body.className === "light" ? "light" : ""
						}`}
						type="submit"
					>
						{" "}
						Submit{" "}
					</button>
				</form>
				<button
					onClick={this.props.autoLocate}
					className={`button ${
						document.body.className === "light" ? "light" : ""
					}`}
				>
					{" "}
					Auto Location{" "}
				</button>
				<div className={`${this.props.values.spinner ? "lds-roller" : ""}`}>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}
}

export default ZipForm;
