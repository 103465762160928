import React, { Component } from 'react'
import axios from 'axios'
import ZipForm from './ZipForm';
import ResortElement from './ResortElement';
import SortOptions from './SortOptions';

export class ResortList extends Component {
    state = {
        step: 1,
        userZip : "",
        latlongResult: "",
        resorts: [],
        userlat: 0,
        userlon: 0,
        reslat: 0,
        reslon: 0,
        closeResorts: [],
        spinner : false,
    }

//#region search resorts, assemble close resort array by distance
//retrieve resorts list basted on latlon
userLocationParse = async (string) => {

    let userLatLonArray = string.split(",")
    let userLat = parseFloat(userLatLonArray[0]).toFixed(6)
    let userLon = parseFloat(userLatLonArray[1]).toFixed(6)
    await this.setState({
        userlat: userLat,
        userlon: userLon
    })
    await this.searchResorts(userLat, userLon);
}
resortLocationParse = async (string) => {
    let resLatLonArray
    if(string != null){
    resLatLonArray = string.split(",")
    
    let resLat = parseFloat(resLatLonArray[0]).toFixed(6)
    let resLon = parseFloat(resLatLonArray[1]).toFixed(6)
    await this.setState({
        reslat: resLat,
        reslon: resLon
    })
   }
}

searchResorts = async (lat, lon) => {
    // call database for resorts info. 
    if (window.location.href === 'http://localhost:3000/'){
        await axios.get('http://localhost:5000/resorts/'+lat+'/'+lon)
        .then(async (res) => {
            await this.setState({
                resorts : res.data
            })
        }).catch(err => console.log(err.response))
    }else {
        await axios.get(window.location.href+'resorts/'+lat+'/'+lon).then(async (res) => {
            await this.setState({
                resorts : res.data
            })
        }).catch(err => console.log(err.response))
    }

    //assemble closeResorts array
    await this.state.resorts.forEach(element => {
                
        this.resortLocationParse(element.latlon);
        const {userlat, userlon, reslat, reslon} = this.state;
        if ((Math.abs(Math.abs(userlat)-Math.abs(reslat))<= 2) 
        && (Math.abs(Math.abs(userlon)-Math.abs(reslon))<= 2))
        {
            this.setState(prevState => ({
                closeResorts: [...prevState.closeResorts, element]
            }))

        }//if end
            
     })//forEach end

    //Distance finder, convert lat lon difference to miles
    let arrayDist = this.state.closeResorts.map( (resort) => {

        this.resortLocationParse(resort.latlon);
        
        const {userlat, userlon, reslat, reslon} = this.state;
        
        let dlat = Math.abs(Math.abs(userlat)-Math.abs(reslat)) * 69
        let dlon = Math.abs(Math.abs(userlon)-Math.abs(reslon)) * 53
        let dist = Math.sqrt((Math.pow(dlat, 2)) + (Math.pow(dlon, 2)))
        
        return {...resort, distance: dist}
        
    })

    arrayDist.sort(compairitator)
    function compairitator(a , b)  {
        return a.distance - b.distance
    }
    
    //Begin powscore algo
    let powScored = arrayDist.map((res)=>{  
        
       let snowScore;
       let weatherScore = 2;
       let windScore;
       let tempScore;

    //newSnow evaluate
    if (res.newSnow === 0)                    {snowScore = 0}
    if ((res.newSnow > 0)&&(res.newSnow <= 3)){snowScore = 3}
    if ((res.newSnow > 3)&&(res.newSnow <= 5)){snowScore = 6}
    if ((res.newSnow > 5)&&(res.newSnow <=12)){snowScore = 8}
    if (res.newSnow > 12)                     {snowScore = 10}
    if (res.newSnow === null)                 {snowScore = null}

    //wind evaluate
    if ((res.windSpeed >= 0)&&(res.windSpeed < 12))  {windScore = 3}
    if ((res.windSpeed >= 12)&&(res.windSpeed < 25)) {windScore = 2}
    if ((res.windSpeed >= 25)&&(res.windSpeed < 40)) {windScore = 1}
    if (res.windSpeed > 40)                          {windScore = 0}
    if (res.windSpeed == null)                      {windScore = null}

    //temp evaluate
    if (res.temp >= 40)                     {tempScore = 0}
    if ((res.temp > 32)&&(res.temp <= 40)) {tempScore = 1}
    if ((res.temp <= 32)&&(res.temp >= 15)){tempScore = 3}
    if ((res.temp < 15)&&(res.temp >= 0))  {tempScore = 2}
    if ((res.temp < 0)&&(res.temp >= -10)) {tempScore = 1}
    if (res.temp < -10)                    {tempScore = 0}
    if (res.temp == null)                  {tempScore = null}

    //weather evaluate
    let sunnyRegx = /Sunny/g;  let isSunny = sunnyRegx.test(res.shortForecast);
    let cloudyRegx = /Cloudy/g;let isCloudy = cloudyRegx.test(res.shortForecast);
    let rainRegx = /Rain/g;    let isRain = rainRegx.test(res.shortForecast);
    let snowRegx = /Snow/g;    let isSnow = snowRegx.test(res.shortForecast);
    let fogRegx = /Fog/g;      let isFog = fogRegx.test(res.shortForecast);
    let clearRegx = /Clear/g;  let isClear = clearRegx.test(res.shortForecast);
    
    if (isSunny) {++weatherScore}
    if (isCloudy){--weatherScore}
    if (isRain)  {weatherScore-=4}
    if (isSnow)  {weatherScore+=2}
    if (isFog)   {--weatherScore}
    if (isClear) {++weatherScore}
    let length=0;
    if (weatherScore){++length};
    if (tempScore){++length}
    if (windScore){++length}
    if (snowScore || snowScore === 0){++length}
    //dump score if really hot
    if (tempScore === 0 && res.temp > 40 && res.temp <= 55) {tempScore-=4}
    if (tempScore === 0 && res.temp > 55) {tempScore-=10}
    let pScore = ((weatherScore + tempScore + windScore + snowScore)/length).toFixed(0);
    if (pScore < 0){pScore = '0'}
    res.powScore = pScore;
    
    return res;

    })

    await this.setState({
        closeResorts : powScored 
    })
}


//#endregion
//#region stepchange 
// next step
nextStep = async () => {
    const { step } = this.state;
    this.setState({
        step: step + 1
    })
}    
// previous step
prevStep = async () => {
    const { step } = this.state;
    this.setState({
        step: step - 1,
        closeResorts : [],
        spinner : false 
    })

}
//#endregion
//#region Form Change/Submit  
    //zip form change event
    userZipChange = (event) => {
        
        this.setState({
            userZip : event.target.value
        })
                
    }
    
    //zip form submit event
    handleSubmit = async (event) => {
        const {userZip} = this.state;
        event.preventDefault();
        await this.setState({
            spinner : true
        })
        //call database to convert userzip to lat long
        
        if (window.location.href === 'http://localhost:3000/'){
            await axios.get('http://localhost:5000/zips/'+ userZip)
        .then(async (res) => 
            
            await this.setState({
            latlongResult: res.data[0][userZip]
            
        }))
        .catch(err => console.log(err))
        }else{
            await axios.get(window.location.href+'zips/'+ userZip)
        .then(async (res) => 
            
            await this.setState({
            latlongResult: res.data[0][userZip]
            
        }))
        .catch(err => console.log(err))
        }

        const { latlongResult } = this.state;        
        if (latlongResult){
            await this.userLocationParse(latlongResult);
        }else{
            await this.setState({
                spinner : false
            })
            alert('Invalid Zip');
            return
        }
        const { closeResorts } = this.state;
        if ( closeResorts.length > 0) {
            this.nextStep();
        }else{
            await this.setState({
                spinner : false
            })
            alert("Server Error, No resorts found nearby")
        }
        
    }
//#endregion
//#region autolocate 
    //autolocate button 
    autoLocate =  (event) => {
        
        if (navigator.geolocation){
            navigator.geolocation.getCurrentPosition(this.assemblePosition)
        } else {
            alert('autoLocation failed :<');
        }
    }
    assemblePosition = async (geoPosition) => {
        
        await this.setState({
            latlongResult : (geoPosition.coords.latitude + ',' + geoPosition.coords.longitude),
            spinner : true
        })
        const { latlongResult } = this.state;
        if (latlongResult){
          await this.userLocationParse(latlongResult);
        }else{
            await this.setState({
                spinner : false
            })
            alert('Error AutoLocating');
            return
        }

        const { closeResorts } = this.state;

        if ( closeResorts.length > 0) {
            this.nextStep();
        }else{
            await this.setState({
                spinner : false
            })
            alert("Server Error, No resorts found nearby, Or location unsupported.")
        }
    }//end auto location script
// #endregion
//#region Sort Option handlers

sortByDistance = async () => {
    
    let distSorted = this.state.closeResorts.sort(compairitator)
    function compairitator(a , b)  {
        return a.distance - b.distance
    }
    await this.setState({        
        closeResorts : distSorted
    })
    
}
sortByPscore = async () => {
    
    let scoreSorted = this.state.closeResorts.sort(compairitator)
    function compairitator(a , b)  {
        return b.powScore - a.powScore
    }
    
    await this.setState({
        closeResorts : scoreSorted
    })
   
}

//#endregion 

render() {
        const { step, userZip, latlongResult, spinner } = this.state;
        const values = { step, userZip, latlongResult, spinner}
        switch(step){
            case 1:
                return(
                    <ZipForm 
                    nextStep = {this.nextStep}
                    autoLocate = {this.autoLocate}
                    userZipChange = {this.userZipChange}
                    handleSubmit = {this.handleSubmit}
                    values = {values}
                    />
                )
            case 2: 
                return (
                    <>
                    <SortOptions
                    sortByDistance = {this.sortByDistance}
                    sortByPscore = {this.sortByPscore}
                    />
                    <ResortElement 
                    closeResorts = {this.state.closeResorts}
                    prevStep = {this.prevStep}
                    />
                    </>
                )
            default: 
                return (
                    <ZipForm 
                    nextStep = {this.nextStep}
                    autoLocate = {this.autoLocate}
                    userZipChange = {this.userZipChange}
                    handleSubmit = {this.handleSubmit}
                    values = {values}
                    />
                 )
            
        }
    }
}

export default ResortList
