import React from 'react';
import ResortList from './components/ResortList';
import Header from'./components/Header';
import Footer from './components/Footer';
import './App.css'
import './css/weather-icons.min.css'

function App() {
  return (
    <div className='content'>
    <Header/>
    <ResortList />
    <Footer/>
    </div>
  );
}


export default App;