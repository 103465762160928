import React from 'react'



export default function Header() {
    const lightToggle = async () => {                
        
        document.body.classList.toggle('light')
        
        await toggler("footer")
        await toggler("mainHeader")
        await toggler("powScoreImg")
        await toggler("resortCard")

        //holy button logic
        if (document.body.className === "light" && 
        document.getElementsByClassName("button")[0].classList[1] !== "light" ){
            await toggler("button")
        }else if (document.body.className === '' && 
        document.getElementsByClassName("button")[0].classList[1] !== '' ){
            await toggler("button")
        }

        
            function toggler (className) {
                let toToggle = document.getElementsByClassName(className)
                for (var i = 0; i < toToggle.length; i++){
                    toToggle[i].classList.toggle('light')
                }
            }
    }   
    
    
    if (localStorage.getItem('lighttheme') === 'true'){
        lightToggle();
    }
    
    const storageToggle = async () => {
        let lighttheme = await localStorage.getItem('lighttheme')
        if (lighttheme === 'true'){
            await localStorage.setItem('lighttheme', false)
        }else{
            await localStorage.setItem('lighttheme', true)
        }
    }

        
    

    return (

    <header className="mainHeader">
       
        <h2>
           <a href={window.location.href}>PowScout</a>
        </h2>
        <div>
            <button onClick={() => {lightToggle(); storageToggle()}}
                className={`button ${document.body.className === 'light' ? 'light' : '' }`}> LightMode
            </button>
        </div>
    </header>
    )
}