import React, { Component } from 'react'

export default class SortOptions extends Component {
    
    render() {
        
        return (
            <div>
                Sort By: 
                <button className={`button ${document.body.className === 'light' ? 'light' : '' }`} onClick={this.props.sortByDistance} autoFocus>Distance</button>
                <button className={`button ${document.body.className === 'light' ? 'light' : '' }`} onClick={this.props.sortByPscore} >Conditions</button>
            </div>
        )
    }
}
